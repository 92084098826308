@use "../../styles/variables" as variables;

.cardOverrides {
  position: relative;
  min-width: 100%;
  max-width: 100%;
  overflow-x: scroll;
  align-items: flex-start;
}

.cardHeader {
  font-size: 1.5rem;
  font-weight: bolder;
  text-transform: uppercase;
  margin: 1rem 0;
}

.cardButton {
  position: absolute;
  top: 20;
  right: 20;
  background-color: #404040;
  align-self: flex-end;
  margin-bottom: 0.25rem;
}

.cardButton:hover {
  background-color: #454545;
}

.buttonsWrapper {
  display: flex;
  padding: 1rem;
}

.button {
  margin-left: 1rem;
}

.titleWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.title {
  margin-bottom: 0rem;
}

.loadingWrapper {
  width: 100%;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.viewMoreLink {
  text-decoration: underline;
  margin: 0.5rem auto 0;
}
