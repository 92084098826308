.livestreamPlayer {
    margin-top: 4rem;
    width: 60rem;
    max-width: 100%;
}

.inactiveStreamsWrapper {
    display: flex;
    justify-content: center;
    width: 100%;
}

.inactiveStream {
    padding: 1rem;
    margin: 0 0.5rem;
    cursor: pointer;
}

.inactiveStream:hover {
    padding: 1rem;
    background: darken($color: #000000, $amount: 10);
}

.subtitle {
    margin-top: 2rem;
}
