@use "../../styles/variables" as variables;

.systemInfoWrapper {
    display: flex;
    align-items: flex-end;
    width: 100%;
    border-bottom: 1px solid variables.$neutral-100;
    padding-bottom: 0.5rem;
    position: sticky;
    top: 0;
    background-color: variables.$neutral-050;
}

.column {
    display: flex;
    flex-direction: column;
    margin: 0 0 0 1rem;
}

.titleOverrides {
    margin: 0 0.5rem 0.5rem 1rem;
}

.contentOverrides {
    text-transform: uppercase;
}