@use "../../styles/variables" as variables;

.menuWrapper {
    margin-right: 1rem;
    height: calc(100vh - variables.$nav-height - 2rem);
    align-items: flex-start;
    width: 20%;
    position: sticky;
    top: calc(variables.$nav-height + 1rem);
    padding: 1.5rem;
}

.label {
    align-self: center;
    margin-bottom: 2rem;
}

.menuItem {
    margin: 0.25rem 0;
}

.activeItem {
    font-weight: bold;
    border-bottom: 1px solid white;
}