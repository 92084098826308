.cardWrapper {
    background-color: #ffffff;
    border-radius: 0.5rem;
    padding: 1rem; 
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    // min-width: 50%;
    // box-shadow: 0px 0px 5px #dadada;
}