@use "../../styles/variables" as variables;

.modalOverlay {
    background-color: #000000da;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    // animation - see .openModal
    transition: all 350ms;
    z-index: -1;
    opacity: 0;
}

.modalWrapper {
    background: variables.$neutral-000;
    border-radius: 1rem;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    // animation - see .openModal
    transition: transform 350ms;
    transform: scale(0);
}

.closeIconWrapper {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
}

.openModal {
    z-index: 999;
    opacity: 1;
    .modalWrapper {
        transform:scale(1);
    }
}

.title {
    font-size: 1.5rem;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 1rem;
    color: black;
    text-align: center;
}

.content {
    font-size: 1.5rem;
    margin: 0.5rem 0;
}

.primaryButton {
    margin-top: 1rem;
}

.neutralButton {
    margin-top: 1rem;
}

.appsWrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
}

.app {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2rem 0;
    width: 100%;
}

.button {
    width: 75%;
}

@media (max-width: 600px) {
    .modalWrapper {
        width: 75vw;
    }

    .closeIconWrapper {
        width: 90vw;
    }

    .app {
        width: 100%;
        margin: 1rem 0;
    }

    .button {
        width: 100%;
    }
}

.cancelButton {
    background-color: white;
    box-shadow: none;
    color: black;
}

.cancelButton:hover {
    background-color: white;
}