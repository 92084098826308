.title {
    font-size: 1.25rem;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 1.5rem;
    text-align: center;
    color: black;
}

.subtitle {
    font-size: 1.125rem;
    text-transform: uppercase;
    margin-bottom: 1.5rem;
    color: black;
}

.label {
    align-self: flex-start;
    color: #888888;
    font-size: 0.75rem;
    text-transform: uppercase;
}

.headerOne {
    color: black;
    font-weight: 100;
    font-size: 3rem;
    letter-spacing: -1.5px;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
}

.headerTwo {
    color: black;
    font-weight: 100;
    font-size: 2.5rem;
    letter-spacing: -0.5px;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
}

.headerThree {
    color: black;
    color: black;
    font-weight: 200;
    font-size: 2rem;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
}

.headerFour {
    color: black;
    font-weight: 400;
    font-size: 1.5rem;
    letter-spacing: 0.25px;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
}

.headerFive {
    color: black;
    font-weight: 400;
    font-size: 1.375rem;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
}

.headerSix {
    color: black;
    font-weight: 500;
    font-size: 1.125rem;
    letter-spacing: 0.15px;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
}

.subtitleOne {
    color: black;
    font-weight: 400;
    font-size: 0.875rem;
    letter-spacing: 0.1px;
    margin: 0 0 0.5rem 0;
}

.subtitleTwo {
    color: black;
    font-weight: 500;
    font-size: 0.875rem;
    letter-spacing: 0.1px;
    margin: 0 0 0.5rem 0;
}

.bodyOne {
    color: black;
    font-weight: 400;
    font-size: 0.875rem;
    letter-spacing: 0.5px;
}

.bodyTwo {
    color: black;
    font-weight: 400;
    font-size: 0.75rem;
    letter-spacing: 0.25px;
}

.label {
    color: black;
    font-weight: 300;
    font-size: 0.75rem;
    letter-spacing: 0.5px;
}

.column {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: 0.5rem;
}

.uppercase {
    text-transform: uppercase;
}