@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-size: 16px;
}

body {
  margin: 0;
  font-family: 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #EEEEEE;
}

@media (min-width: 2500px) {
  html {
    font-size: 32px;
  }
}

@media (max-width: 600px) {
  html {
    font-size: 12px;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

main::-webkit-scrollbar {
  width: 0.5em;
  background-color: #181D22;
}
 
main::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #181D22;
}
 
main::-webkit-scrollbar-thumb {
  background-color: darkgray;
}
