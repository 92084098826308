@use "../../styles/variables" as variables;

.cardHeader {
    font-size: 1.5rem;
    font-weight: bolder;
    text-transform: uppercase;
    margin: 1rem 0;
}

.actionsAndSearchWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.actionButtonOverrides {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    color: white;
    background-color: #2196f3;
}

.actionButtonOverrides:hover {
    background-color: #42a5f5;
}

.search {
    border-radius: 0.5rem;
    border: none;
    padding: 0.5rem 1rem;
    align-self: flex-end;
}

.tableWrapper {
    padding: 1rem;
    align-self: flex-start;
}

.tableRowHeader {
    display: flex;
    border-bottom: 1px solid white;
    padding: 0 1rem;
}

.tableHeader {
    text-align: left;
    padding: 1rem 0.5rem;
    width: fit-content;
}

.tableBody {
    max-height: calc(50vh - variables.$nav-height);
    overflow-y: scroll;
    // display: block;
}

.tableRow {
    display: flex;
    align-items: center;
    border-bottom: 1px solid white;
    padding: 0 1rem;
}

.tableData {
    padding: 1rem 0.5rem;
    overflow: hidden;
    text-overflow: wrap;
}

.tableButton {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        padding: 0.75rem 1.5rem;
        text-decoration: underline;
        color: black;
        border-radius: 0.25rem;
}

.noData {
    padding: 1rem;
    text-align: center;
}

.actionsButton {
    :global(.MuiButton-root) {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.5);
        border: 1px solid #84CE23;
        padding: 1rem 1.5rem;
        text-transform: uppercase;
        color: #84CE23;
        border-radius: 0.25rem;
        width: fit-content; 
        margin: 0.5rem 0;
        overflow: visible;
    }
}

.menuTriangle {
    height: 25px;
    width: 25px;
    background: white;
    position: absolute;
    top: -12.5px;
}