@use "../../styles/variables" as variables;

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - variables.$nav-height);
}

.formWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    font-size: 1.25rem;
    font-weight: bold;
    text-align: center;
    color: black;
    margin: 1rem 0;
}

.label {
    align-self: flex-start;
    color: #9c9c9c;
}

.input {
    padding: 1rem;
    width: 20rem;
    margin: 0.5rem 0 1rem 0;
    background-color: #e9e9e9;
    border-radius: 0.25rem;
    color: black;
}

.button {
    margin-top: 1rem;
}

.link {
    margin: 1rem 0 0.5rem 0;
    color: lightgray;
    cursor: pointer;
    padding-bottom: 0.125rem;
    border-bottom: 1px solid white;
}