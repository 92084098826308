.loaderWrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.95);
    z-index: 2;
}

.loadingLogoWrapper {
    height: 20rem;
    width: 20rem;
}

.loadingText {
    margin-top: 1rem;
    font-size: 3.5rem;
    animation: color 2000ms infinite;
}

.gear {
    fill: white;
    animation: rotate 30000ms linear infinite;
    transform-origin: center;
}

@keyframes rotate {
    from {
      transform: rotate(0deg);
    }
  
    to {
      transform: rotate(360deg);
    }
  }

  @keyframes color {
    0% {
      color: rgb(160, 160, 160);
    }
  
    50% {
      color: white;
    }
    
    100% {
        color: rgb(160, 160, 160);
    }
  }