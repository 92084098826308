@use "../../styles/variables" as variables;

// top nav
// .mainWrapper {
//     padding: variables.$nav-height 1rem 1rem;
//     min-height: 100vh;
//     width: 100vw;
// }

// side nav
.mainWrapper {
  position: absolute;
  top: 0;
  right: 0;
  min-height: 100vh;
  overflow-y: auto;
  width: calc(100vw - variables.$nav-width);
}

.routeWrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  position: relative;
}

.loadingWrapper {
  position: fixed;
  top: 0;
  right: 0;
  width: calc(100vw - variables.$nav-width);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f3f3f3;
  z-index: 9;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  position: relative;
  padding: 1rem;
  overflow-y: scroll;
}

.noScroll {
  overflow: hidden;
}
