@use "../../styles/variables" as variables;

.label {
    align-self: flex-start;
    color: #919191;
    font-size: 0.75rem;
    text-transform: uppercase;
}

.input {
    font-size: 1rem;
    padding: 0.5rem 1rem;
    width: 20rem;
    margin: 0.5rem 0;
    border-radius: 0.5rem;
    color: black;
    // border: 1px solid #b7b8bb;
    // box-shadow: 0px 0px 3px #e5e7eb;
    background-color: variables.$neutral-050;
}

.required:after {
    content:" *";
    color: red;
}

.readOnlyInput {
    background-color: transparent;
    padding: 0;
    color: white;
    font-size: 1.25rem;
}
