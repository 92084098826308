@use "../../styles/variables" as variables;

.alarmWrapper {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 100%;
  background-color: rgba(variables.$red-200, 0.33);
  border: 1px solid variables.$red-600;
  border-radius: 0.5rem;
  text-align: left;
  margin: 0.25rem 0;
}

.alarmTitleOverrides {
  margin-bottom: 0rem;
  font-weight: bolder;
}

.actionsTitleOverrides {
  margin: 0.5rem 0;
  text-align: center;
}

.alarmSubtitleOverrides {
  margin-bottom: 0rem;
  color: variables.$neutral-700;
  font-weight: 200;
}

.alarmContentOverrides {
  margin: 0;
}

.hasAlarms {
  transform: translateY(0);
}

.buttonsWrapper {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.buttonOverrides {
  width: fit-content;
}

.nonCriticalAlarm {
  background-color: rgba(variables.$orange-200, 0.33);
  border: 1px solid variables.$orange-800;
}
