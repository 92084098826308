@use "../../styles/variables" as variables;

// top nav
// .navWrapper {
//     position: fixed;
//     top: 0;
//     right: 0;
//     left: 0;
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     padding: 1rem;
//     transition: all 0.5s;
//     height: variables.$nav-height;
//     background-color: #ffffff;
//     z-index: 1;
//     box-shadow: 0px 0px 5px #cecece;
// }

// side nav
.navWrapper {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    transition: all 0.5s;
    height: 100vh;
    width: variables.$nav-width;
    background-color: variables.$neutral-000;
    box-shadow: 0px 0px 5px variables.$neutral-100;
}

.logoWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // top nav
    // margin: 0 1rem;
    // bottom nav
    margin: 1rem 0;
}

.logo {
    width: 4rem;
}

.logoText {
    color: black;
    margin-right: 0.75rem;
    margin-top: 0.25rem;
    margin-bottom: -0.25rem;
    font-size: 0.75rem;
}

.menuItem {
    margin: 0.25rem 0;
    font-size: 0.875rem;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    cursor: pointer;
    z-index: 2;
}

.menuItem:hover {
    background-color: variables.$neutral-100;
}

.activeItem {
    font-weight: bold;
    background-color: variables.$neutral-100;
}

.selectedItemWrapper {
    cursor: pointer;
    font-size: 0.875rem;
    margin-top: -0.5rem;
    padding: 0.75rem 1rem 0.5rem;
    border-radius: 0 0 0.5rem 0.5rem;
    background-color: variables.$neutral-050;
    display: flex;
    align-items: center;
}
