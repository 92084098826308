.systemStatusWrapper {
  display: flex;
  flex-direction: column;
}

.cardOverrides {
  flex-direction: row;
  align-items: flex-start;
  align-items: center;
  margin-top: 1rem;
}

.column {
  display: flex;
  flex-direction: column;
}

.titleOverrides {
  margin-bottom: 0.5rem;
}

.contentOverrides {
  text-transform: uppercase;
}

.buttonOverrides {
  padding: 1rem;
  width: fit-content;
  margin-left: 1rem;
}
