// colors
$neutral-000: #FFFFFF;
$neutral-050: #EEEEEE;
$neutral-100: #DADADA;
$neutral-200: #B1B1B1;
$neutral-300: #9A9A9A;
$neutral-400: #757575;
$neutral-500: #606060;
$neutral-600: #404040;
$neutral-700: #303030;
$neutral-800: #202020;
$neutral-900: #101010;

$blue-200: #45adfd;
$blue-400: #259FFF;
$blue-600: #2196F2;
$blue-800: #1b86d8;

$green-200: #92e722;
$green-400: #84ce23;
$green-600: #74b61f;
$green-800: #609c11;

$red-200: #ff695e;
$red-400: #FF493C;
$red-600: #F44336;
$red-800: #da372c;

$orange-200: #fdc167;
$orange-400: #FFB74D;
$orange-600: #ECA946;
$orange-800: #e29828;

// sizing
$nav-height: 75px;
$nav-width: 15rem;
$menu-width: 20%;