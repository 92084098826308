.wrapper {
    display: flex;
    flex-direction: column;
    padding: 1rem 1rem;
    max-height: 80vh;
}

.button {
    margin-top: 1rem;
    align-self: center;
}

.subtitle {
    text-align: center;
    margin: 1rem 0;
}

.requiredAsterisk {
    color: red;
}