@use "../../styles/variables" as variables;

.cardOverrides {
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto;
  min-width: 40%;
}

.titleOverrides {
  align-self: center;
  margin-bottom: 0;
}

.column {
  display: flex;
  flex-direction: column;
  margin: 0 0 0.25rem 0;
}

.column:last-child {
  margin: 0;
}

.contentOverrides {
  text-transform: uppercase;
}

.navigationWrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0.5rem auto 0;
}

.navigationItem {
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  border-radius: 0.5rem;
  margin: 0.5rem;
  background-color: variables.$neutral-100;
}

.activeNavigationItem {
  background-color: variables.$blue-400;
  color: white;
}

.activeNavigationItemWrapper {
  margin-top: 0.5rem;
  width: 100%;
}

// with card based system state view
.progressItemWrapper {
  display: flex;
  align-items: center;
  width: 66%;
  margin: 0.25rem auto;
}

.progressItemText {
  text-transform: uppercase;
  margin-left: 0.5rem;
}

// system view svg
.systemViewWrapper {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  margin-top: 2rem;
  position: relative;

  .svg {
    transform: rotate(180deg);
  }
}

.outline {
  fill: variables.$neutral-600;
}

.fill {
  fill: variables.$neutral-100;
}

.group {
  cursor: pointer;
}

.group:hover {
  .fill {
    fill: variables.$neutral-100;
  }
}

.laser {
  fill: red;
}

.pink {
  fill: pink;
}

// .group:hover {
//     .fill {
//         fill: #808080;
//     }
// }

.systemViewOverlayWrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wheelProgressWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.tireProgressColumnWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.systemStateCardOverrides {
  background-color: rgba(variables.$neutral-700, 0.95);
  border: 2px solid black;
  margin: 0.5rem 0;
  min-width: unset;
  padding: 1rem;
}

.vehicleDataOverrides {
  width: 20rem;
  align-items: flex-start;
}

.systemStateCardTitleOverrides {
  align-self: center;
  color: white;
}

.systemStateCardLabelOverrides {
  color: white;
  text-transform: uppercase;
}

.systemStateCardContentOverrides {
  color: white;
  text-transform: uppercase;
}
// with system view svg

.progressItemWrapperSvg {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0.5rem 0;
}

.progressItemTextSvg {
  text-transform: uppercase;
  margin-left: 0.5rem;
  font-size: 0.75rem;
  color: white;
}

.cardTitleOverrides {
  color: white;
}

.componentStatusWrapper {
  height: 100%;
  width: 100%;
  position: relative;
}

.driverRobot {
  align-items: flex-start;
  position: absolute;
  top: 50%;
  left: 20%;
  transform: translate(-50%, -50%);
}

.passengerRobot {
  align-items: flex-start;
  position: absolute;
  top: 50%;
  right: 20%;
  transform: translate(50%, -50%);
}

.viewControlsWrapper {
  display: flex;
  margin-left: auto;
}

.controlButtonWrapper {
  padding: 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
}

.controlButtonWrapper:hover {
  background-color: variables.$neutral-200;
}

.activeControlButtonWrapper {
  background-color: variables.$neutral-200;
}

.mainFieldsCardOverrides {
  padding: 1rem 0;
  margin-top: -1rem;
}

.mainFieldsWrapper {
  display: flex;
  flex-wrap: wrap;

  .column {
    margin: 0 1rem;
  }
}

.jobProgressCardOverrides {
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  // background-color: transparent;
}

.jobProgressCardContentWrapper {
  display: flex;
  justify-content: center;

  svg {
    width: 25%;
  }
}

.jobProgressCardColumn {
  display: flex;
  flex-direction: column;
}

.jobProgressCardColumn:first-child {
  margin-right: 1rem;
}

.jobProgressCardColumn:last-child {
  margin-left: 1rem;
}

.wheelProgressItemWrapper:first-child {
  margin-bottom: 1rem;
}

.wheelProgressItemWrapper {
  padding: 1rem 1rem;
  background-color: variables.$neutral-600;
  border: 2px solid variables.$neutral-800;
  border-radius: 0.5rem;
  text-transform: uppercase;
}

.wheelProgressTitleOverrides {
  text-align: center;
  color: variables.$neutral-000;
}

.wheelProgressSingleItemWrapper {
  display: flex;
  margin: 0.125rem 0;
}

.dot {
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 50%;
  background-color: variables.$neutral-300;
  margin: 0.5rem 0.5rem 0 0;
}

.activeDot {
  background-color: variables.$neutral-000;
}

.completedDot {
  background-color: variables.$green-200;
}

.wheelProgressItemText {
  color: variables.$neutral-500;
  margin-bottom: 0;
}

.completeWheelProgressItem {
  color: variables.$neutral-300;
}

.activeWheelProgressItem {
  font-weight: bold;
  color: variables.$neutral-000;
}

.wheelProgressItemActiveText {
  color: variables.$neutral-000;
  margin-bottom: 0;
  font-size: 0.75rem;
  margin-top: -0.25rem;
}

.wheelProgressItemCompleteText {
  color: variables.$neutral-300;
  margin-bottom: 0;
  font-size: 0.625rem;
  margin-top: -0.25rem;
}

.wheelProgressItemNotStartedText {
  color: variables.$neutral-500;
  margin-bottom: 0;
  font-size: 0.625rem;
  margin-top: -0.25rem;
}

.systemSideMainFieldsWrapper {
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
  width: 100%;

  .column {
    margin: 0rem 0.5rem;
  }
}

.hr {
  height: 1px;
  width: calc(100% - 2rem);
  background-color: variables.$neutral-100;
  margin: 0.5rem 1rem;
}

.systemSideNavLabelOverrides {
  text-align: center;
  width: 100%;
}

.subNavItemWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.5rem;
}

.subNavItemTitleOverrides {
  width: 100%;
  text-align: center;
  margin-bottom: 0;
}

.infoSectionWrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0.5rem 1rem;
}

.alarmTitleOverrides {
  align-self: flex-start;
}

.viewSystemLink {
  text-decoration: underline;
  margin: 0.5rem 0 0;
  padding: 0.5rem;
  text-transform: uppercase;
  color: rgba(variables.$neutral-500, 0.95);
  cursor: pointer;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  width: fit-content;
}

.torqueValuesWrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color: rgba(variables.$neutral-200, 0.33);
  border-radius: 0.5rem;
  padding: 0.25rem;
}

.torqueValueWrapper {
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0.125rem;
  padding: 0.125rem 0.25rem;
  border: 1px solid transparent;
}

.underTorqueSpec {
  background-color: variables.$red-200;
  border: 1px solid variables.$red-600;
}

.headerOverrides {
  margin: 0.5rem 0;
  font-size: 1.25rem;
  font-weight: 300;
}

.torqueValueSubtitleOverrides {
  margin-bottom: 0rem;
}

.torqueValueBodyOverrides {
  margin-bottom: 0rem;
  font-weight: bold;
}
