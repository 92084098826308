@use "../../styles/variables" as variables;

.link {
  background-color: transparent;
  padding: 1rem;
  cursor: pointer;
  color: black;
  text-transform: uppercase;
  font-size: 0.875rem;
}

.button {
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  border: none;
  color: white;
  font-size: 1rem;
  letter-spacing: 0.25px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.5;
  position: relative;
  cursor: pointer;
}

.primary {
  background-color: variables.$blue-600;
}

.primary:hover,
.primary:focus {
  background-color: variables.$blue-400;
}

.secondary {
  background-color: variables.$orange-600;
}

.secondary:hover,
.secondary:focus {
  background-color: variables.$orange-400;
}

.positive {
  background-color: variables.$green-600;
}

.positive:hover,
.positive:focus {
  background-color: variables.$green-400;
}

.negative {
  background-color: variables.$red-600;
}

.negative:hover,
.negative:focus {
  background-color: variables.$red-400;
}

.neutral {
  background-color: variables.$neutral-600;
}

.neutral:hover,
.neutral:focus {
  background-color: variables.$neutral-500;
}

.button:hover {
  box-shadow: 0px 0px 5px #444444;
}

.disabled {
  background-color: variables.$neutral-700;
  opacity: 0.5;
}

.disabled:hover {
  box-shadow: none;
  background-color: variables.$neutral-700;
  cursor: initial;
}

.navButton {
  padding: 1rem 2rem;
}

.loadingWrapper {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0.5rem;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: variables.$neutral-500;
}
